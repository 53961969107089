import { reactive } from "vue";

/**
 * @type {UnwrapNestedRefs<{status: {variants: {IN_PROGRESS: string, COMPLETED: string, DRAFT: string, CANCELED: string, PENDING: string, ARCHIVED: string, ON_HOLD: string}}}>}
 */
const state = reactive({
  status: {
    variants: {
      ON_HOLD: "bg-gray-400",
      PENDING: "bg-yellow-400",
      IN_PROGRESS: "bg-green-500",
      COMPLETED: "bg-green-900",
      CANCELED: "bg-red-500",
      DRAFT: "bg-red-900",
      ARCHIVED: "bg-gray-900"
    },
  }
});

/**
 * @returns {{status: *}}
 */
export function useProject() {
  return {
    status: state.status
  };
}
