<script>
import { defineComponent, reactive, computed } from "vue";

import { useRoute } from "vue-router";

import { useStore } from "vuex";

import { useProject } from "@/compositions/models/useProject";

const ProjectModel = {
  name: "",
  description: "",
  budget: null,
  estimated_amount: null,
  skills: [],
  status: {}
};

export default defineComponent({
  name: "ProjectShow",
  setup() {
    const route = useRoute();
    const project = useProject();
    const store = useStore();

    const state = reactive({
      projectId: route.params.id,
      project: JSON.parse(JSON.stringify(ProjectModel))
    });

    store.dispatch("project/getProject", state.projectId).then(r => {
      state.project = r.data;
    });

    return {
      status_variants: project.status.variants,
      project: computed(() => state.project),
      route
    };
  }
});
</script>

<template>
  <!-- BEGIN: Project Info -->
  <div class="intro-y box bg-white px-5 pt-5 mt-5">
    <div
      class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
    >
      <div
        class="flex flex-1 px-5 items-center justify-center lg:justify-start"
      >
        <div class="ml-5">
          <div
            class="wsm:w-40 truncate sm:whitespace-normal font-medium text-lg"
          >
            {{ project.name }}
          </div>
          <div class="text-gray-600">
            <div class="tags">
              <span
                v-for="skill in project.skills"
                :key="skill.id"
                class="bg-blue-100 rounded p-1 ml-1 text-gray-600 text-xs whitespace-no-wrap"
              >
                {{ skill.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
      >
        <div class="font-medium text-center flex items-center">
          <i class="ri-bar-chart-2-line mr-2" /> Status
        </div>
        <div
          class="flex items-center justify-center text-white rounded py-1 px-2"
          :class="status_variants[project.status.key]"
        >
          {{ project.status.value }}
        </div>
      </div>
      <div
        class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
      >
        <div class="font-medium  flex items-center text-center lg:text-left">
          <i class="ri-time-line mr-2" /> Created date
        </div>
        <div class="flex items-center justify-center lg:justify-start">
          <div class="flex">
            {{ project.created_at }}
          </div>
        </div>
      </div>
      <div
        class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
      >
        <div class="font-medium  flex items-center text-center lg:text-left">
          <i class="ri-timer-2-line mr-2" /> Start date
        </div>
        <div class="flex items-center justify-center lg:justify-start">
          <div class="flex">
            {{ project.started_at }}
          </div>
        </div>
      </div>
      <div
        class="flex mt-6 lg:mt-0 items-center lg:items-start flex-1 flex-col justify-center text-gray-600 dark:text-gray-300 px-5 border-l border-r border-gray-200 dark:border-dark-5 border-t lg:border-t-0 pt-5 lg:pt-0"
      >
        <div class="font-medium  flex items-center text-center lg:text-left">
          <i class="ri-timer-flash-line mr-2" /> End date
        </div>
        <div class="flex items-center justify-center lg:justify-start">
          <div class="flex">
            {{ project.ended_at }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start"
    >
      <router-link
        class="py-4 sm:mr-8"
        :class="{'active': $route.name === 'dashboard.client.project.show.overview'}"
        :to="{
          name: 'dashboard.client.project.show.overview',
          params: { id: project.id }
        }"
      >
        Overview
      </router-link>
      <router-link
        class="py-4 sm:mr-8"
        :class="{'active': $route.name === 'dashboard.client.project.show.teams'}"
        :to="{
          name: 'dashboard.client.project.show.teams',
          params: { id: project.id }
        }"
      >
        Teams
      </router-link>
    </div>
  </div>
  <div class="intro-y tab-content mt-5 pb-5">
    <router-view :key="route.params.id" />
  </div>
</template>
